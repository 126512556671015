import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="bio">
          <h1>Hello, I'm Edwin</h1>
          <p>
            I'm a Staff UX Engineer at Google. I'm on the Workspace Labs team
            currently, leading the prototyping efforts for Generative Media use
            cases. Ex-UX Lead for Codey - Google's Code Generation model
            integrated on various Google products.
          </p>
          <p>
            Prior to Google, I worked at a few digital agencies and also ran one
            myself. I have experience leading tech teams and building apps &
            websites.
          </p>
          <div className="papers">
            <h3>Published Papers</h3>
            <ul>
              <li>
                <a
                  href="https://research.google/pubs/pub52139/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Discovering the Syntax and Strategies of Natural Language
                  Programming with Generative Language Models
                </a>
              </li>
              <li>
                <a
                  href="https://dl.acm.org/doi/10.1145/3474349.3480209"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  GenLine and GenForm: Two Tools for Interacting with Generative
                  Language Models in a Code Editor
                </a>
              </li>
              <li>
                <a
                  href="https://research.google/pubs/pub51353/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Prompt-based Prototyping with Large Language Models
                </a>
              </li>
              <li>
                <a
                  href="https://research.google/pubs/pub50554/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Tone Transfer: In-Browser Interactive Neural Audio Synthesis
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="work">
          <h2>Selected Work</h2>
          <ul>
            <li>
              <a
                href="https://developers.googleblog.com/2023/03/announcing-palm-api-and-makersuite.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>Codey</h3>
                <img src="./genai.png" alt="genai features" />
              </a>
              <ul className="details">
                <li className="role">UX Lead</li>
                <li>
                  As the UX Lead at Codey, I helped design, prompt engineer, and
                  evaluate prompts for launched features like{" "}
                  <a
                    href="https://blog.google/products/search/search-labs-ai-announcement-/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    "Code Tips"
                  </a>{" "}
                  on Search Labs,{" "}
                  <a
                    href="https://blog.google/technology/developers/google-colab-ai-coding-features/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Google Colab
                  </a>
                  's AI Code Assistance features, Android Studio's{" "}
                  <a
                    href="https://developer.android.com/studio/preview/studio-bot"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Studio Bot
                  </a>
                  , and even Bard's{" "}
                  <a
                    href="https://searchengineland.com/google-bard-adds-genuine-citations-in-responses-and-more-concise-summaries-423143"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Recitation
                  </a>{" "}
                  features.
                </li>
              </ul>
            </li>
            <li>
              <a
                href="https://developers.googleblog.com/2023/03/announcing-palm-api-and-makersuite.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>Google AI Studio</h3>
                <img
                  src="./makersuite.png"
                  alt="makersuite website screenshot"
                />
              </a>
              <ul className="details">
                <li className="role">Tech Lead / UX Contributor</li>
                <li>
                  In the last 2 years, I have been heavily involved with Large
                  Language Models (LLMs) work at Google. To help democratize
                  LLMs, I created a platform that allowed fellow Googlers to
                  share and save their prompt designs. The tool found major
                  success and is used daily by thousands of Googlers and
                  eventually became the inspiration for the{" "}
                  <a
                    href="https://developers.googleblog.com/2023/03/announcing-palm-api-and-makersuite.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MakerSuite
                  </a>{" "}
                  product. Co-author of
                  <a
                    href="https://research.google/pubs/pub52139/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    paper
                  </a>
                  .
                </li>
              </ul>
            </li>
            <li>
              <a
                href="https://dl.acm.org/doi/10.1145/3474349.3480209"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3>Paper: Inline Code Gen</h3>
                <video src="./genline.mp4" autoPlay loop muted />
              </a>
              <ul className="details">
                <li className="role">Lead UX Engineer</li>
                <li>
                  In March 2021, I helped a Research team revolutionize how an
                  inline code assistant can look like by building and designing
                  a [[token pattern]] to invoke inline UI to generate code using
                  LLMs. This was before the release of CoPilot and ChatGPT. We
                  eventually published a{" "}
                  <a
                    href="https://dl.acm.org/doi/10.1145/3474349.3480209"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    paper
                  </a>{" "}
                  with our learnings.
                </li>
              </ul>
            </li>
            <li>
              <a
                href="https://pair.withgoogle.com/guidebook"
                target="_blank"
                rel="noreferrer noopener"
              >
                <h3>PAIR Guidebook</h3>
                <img
                  src="./pair-guidebook.png"
                  alt="pair guidebook website screenshot"
                />
              </a>
              <ul className="details">
                <li className="role">Tech Lead</li>
                <li>
                  Next.JS statically generated site pulling data from content
                  managed by a CMS, stored in Firebase.
                </li>
                <li>Site is fully accessible on mobile and desktop.</li>
              </ul>
            </li>
            <li>
              <a
                href="https://sites.research.google/tonetransfer"
                target="_blank"
                rel="noreferrer noopener"
              >
                <h3>Tone Transfer</h3>
                <img
                  src="./tone-transfer.png"
                  alt="tone transfer website screenshot"
                />
              </a>
              <ul className="details">
                <li className="role">
                  Lead Integration Engineer & Co-frontend Engineer
                </li>
                <li>Integrated ML features as well as built frontend</li>
                <li>
                  Open sourced{" "}
                  <a
                    href="https://github.com/magenta/magenta-js/tree/master/music/src/ddsp"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    DDSP
                  </a>{" "}
                  as part of Magenta JS. Worked closely with Research
                  Scientists, Tensorflow.js teams to bring a python model to a
                  local model that runs even on mobile devices. Co-author of
                  <a
                    href="https://research.google/pubs/pub50554/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    paper
                  </a>
                  .
                </li>
              </ul>
            </li>
            <li>
              <a
                href="https://www.google.com/search?sxsrf=ALeKk00jEzKUdVsP_DT-sCoOVXsYRFzJDA%3A1587257202115&ei=cp-bXq-FBdG90PEPxfC1eA&q=how+to+pronounce+entrepreneur&oq=how+to+pronounce+entre&gs_lcp=CgZwc3ktYWIQAxgAMgIIADICCAAyAggAMgIIADICCAAyAggAMgIIADICCAAyAggAMgIIADoECAAQRzoECCMQJzoFCAAQgwFQ2R1YtiNgySpoAHADeACAAXmIAe4DkgEDMy4ymAEAoAEBqgEHZ3dzLXdpeg&sclient=psy-ab"
                target="_blank"
                rel="noreferrer noopener"
              >
                <h3>Google "How to Pronounce"</h3>
                <img src="./how-to-pronounce.png" alt="how to pronounce" />
              </a>
              <ul className="details">
                <li className="role">UX Engineer</li>
                <li>
                  Prototyped and helped launch a Google Search "OneBox" feature
                  that teaches users how to pronounce words in American and
                  British accents.{" "}
                  <a href="https://thenextweb.com/google/2019/11/15/googles-new-feature-will-correct-your-pronunciation/">
                    TheNextWeb Feature
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="http://www.aldoshoes.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                <h3>Aldo</h3>
                <img src="./aldo.png" alt="aldo website screenshot" />
              </a>
              <ul className="details">
                <li className="role">Lead Frontend Engineer</li>
                <li>
                  Led team of 5-7 frontend engineers for full rewrite of this
                  isomorphic React App that is also fully accessible.
                </li>
                <li>
                  {" "}
                  <a href="https://www.invisionapp.com/blog/aldo-website-redesign/">
                    Case study
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
